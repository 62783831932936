const algoliaEnv = require("./algolia-env.js");
import algoliasearch from "algoliasearch/lite";

export const algoliaConfig = {
  ALGOLIA_APP_ID: process.env.GATSBY_ALGOLIA_APP_ID || "",
  ALGOLIA_SEARCH_ONLY_API_KEY: process.env.GATSBY_ALGOLIA_SEARCH_KEY || "",
  ALGOLIA_WRITE_API_KEY: process.env.ALGOLIA_WRITE_API_KEY || "",
  ALGOLIA_ENV: algoliaEnv.env,
};

export const searchIndex = {
  siteSearch: algoliaEnv.index.siteSearch,
  scientists: algoliaEnv.index.scientists,
  events: algoliaEnv.index.events,
  eventsPopular: algoliaEnv.index.eventsPopular,
  eventsPast: algoliaEnv.index.eventsPast,
  arboretum: algoliaEnv.index.arboretum,
};

export const searchClient = algoliasearch(
  algoliaConfig.ALGOLIA_APP_ID,
  algoliaConfig.ALGOLIA_SEARCH_ONLY_API_KEY
);

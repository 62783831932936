const algoliaEnv = process.env.GATSBY_ALGOLIA_ENV
  ? process.env.GATSBY_ALGOLIA_ENV
  : "Stage";

exports.env = algoliaEnv;
exports.index = {
  siteSearch: `Site Search ${algoliaEnv}`,
  scientists: `Scientists ${algoliaEnv}`,
  events: `Events ${algoliaEnv}`,
  eventsPopular: `Events ${algoliaEnv} Popular`,
  eventsPast: `Events ${algoliaEnv} Past`,
  arboretum: `Arboretum ${algoliaEnv}`,
};

import React, { useCallback, useState } from "react";
import { connectSearchBox } from "react-instantsearch-dom";
import { SearchBoxProvided } from "react-instantsearch-core";
import { SearchInput } from "./SearchInput";

interface Props extends SearchBoxProvided {
  delay: number;
  placeHolder: string;
}

const CustomSearchBox = (props: Props) => {
  const [value, setValue] = useState(props.currentRefinement);
  const [timerId, setTimerId] = useState<any>(null);
  const { refine, delay } = props;

  const onChangeDebounced = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const val = event.currentTarget.value;

      clearTimeout(timerId);
      let tId = setTimeout(() => {
        refine(val);
      }, delay);
      setTimerId(tId);

      setValue(val);
    },
    [refine, delay, timerId]
  );

  return (
    <div className="ais-SearchBox" id="search">
      <form
        noValidate
        className="ais-SearchBox-form"
        action=""
        onSubmit={(e) => e.preventDefault()}
        role="search"
      >
        <SearchInput
          placeHolder={props.placeHolder}
          inputProps={{
            autoComplete: "off",
            autoCorrect: "off",
            autoCapitalize: "off",
            spellCheck: "false",
            required: true,
            maxLength: 512,
            value: value,
            onChange: onChangeDebounced,
          }}
        />
      </form>
    </div>
  );
};

const DebouncedSearchBox = connectSearchBox(CustomSearchBox);

export default DebouncedSearchBox;
